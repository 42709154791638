<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">

          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">名称:</span>
              <el-input placeholder="名称" size="small" style="width:265px" v-model="scName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">分类:</span>
              <el-select size="small" clearable v-model="categoryId" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-row type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="80" class="th-l">图片</th>
                <th width="130" class="th-l">名称</th>
                <th width="130" class="th-l">属性</th>
                <th width="130" class="th-l">分类</th>
                <th width="130" class="th-l">价格</th>
                <th width="130" class="th-l">销量</th>
                <th width="160" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">

                <td class="td-l"><img :src="row.imgUrl" style="width: 50px;height: 50px"/></td>

                <td class="td-l"><span>{{row.scName}}</span></td>

                <td class="td-l"><span v-if="row.specs">{{row.specs.replace(/\[|]|"/g,'')}}</span></td>

                <td class="td-l"><span>{{row.categoryName}}</span></td>

                <td class="td-l">{{row.skuPrice}}</td>

                <td class="td-l"></td>

                <td class="td-l"><span>{{row.createTime}}</span></td>

                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="">订单列表</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="toUpdateEvent(index)">上架</el-button>
                  <!--<el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>-->
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 上架提示 -->
    <el-dialog title="提示" :visible.sync="upVisible" width="300px" center>
      <div class="del-dialog-cnt">是否上架商品？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="upVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateMethod">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        scName: '',
        categoryId: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        upVisible: false,
        delVisible: false,
        editItem: {},

        categoryMap: {},
        categoryList: [],
      }
    },
    async created() {
      this.searchEvent();
      this.getCategoryList();
    },
    methods: {
      async getCategoryList() {
        let data = {
          categoryType: '1'
        }
        let res = await this.$get("/admin/getMspCategoryList", data)
        this.categoryList = res.categoryList
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          scName: this.scName,
          categoryId: this.categoryId,
          largeType: '1',
          isShelves: '0',
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getMspSkuForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
        this.$router.push("/microMalls/saveSale")
      },

      editEvent(index) {
        this.editItem = this.list[index]
        this.$router.push("/microMalls/updateSale?id=" + this.list[index].id)
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      toUpdateEvent(index) {
        this.editItem = this.list[index]
        this.upVisible = true

      },
      async updateMethod() {
        let data = {
          id: this.editItem.id,
          isShelves: '1'
        };
        let res = await this.$post("/admin/updateMspSku", data)
        if (res.code == 200) {
          this.searchEvent()
          this.upVisible = false
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },
      //删除
      async delMethod() {
        let data = {
          id: this.editItem.id
        };

        let res = await this.$post("/admin/delete_mspSku", data)
        if (res.code == 200) {
          this.searchEvent();
          this.delVisible = false;
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },
    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
